export const insurancePlansByState = {
  Arizona: [
    "Coming soon: Aetna Behavioral Health",
    "Blue Cross Blue Shield",
    "Cigna",
    "First Health/Coventry",
    "Humana",
    "United/Optum Behavioral Health",
    "Self-pay",
  ],
  California: [
    "Aetna/Aetna Behavioral Health",
    "Anthem Blue Cross",
    "Blue Shield of California/Magellan",
    "Cigna",
    "ComPsych",
    "Easterseals",
    "First Health/Coventry",
    "HealthNet",
    "Managed Health Network",
    "MCCP",
    "MultiPlan",
    "Provider Network of America",
    "UnitedHealthcare/Optum Behavioral Health",
    "Self-pay",
  ],
  Connecticut: [
    "Aetna/Aetna Behavioral Health",
    "Anthem Blue Cross Blue Shield",
    "Carelon",
    "Cigna/Evernorth",
    "ConnectiCare",
    "Humana",
    "UnitedHealthcare/Optum Behavioral Health",
    "Self-pay",
  ],
  Illinois: [
    "Aetna/Aetna Behavioral Health",
    "Blue Cross Blue Shield",
    "Cigna/Evernorth",
    "MultiPlan",
    "UnitedHealthcare/Optum Behavioral Health",
    "Self-pay",
  ],
  Massachusetts: [
    "Aetna/Aetna Behavioral Health",
    "Be Healthy (Health New England)",
    "Blue Cross Blue Shield",
    "BMC Health Plan/Wellsense",
    "Cigna/Evernorth",
    "Fallon Community Health Plan",
    "Harvard Pilgrim Health Plan",
    "Mass General Brigham Health Plan",
    "MassHealth (Medicaid)",
    "Tufts Health Plan",
    "Tufts Health Public Plans",
    "Tufts Health Together (MassHealth ACO)",
    "United/Optum Behavioral Health",
    "Self-pay",
  ],
  "New Jersey": [
    "Aetna/Aetna Behavioral Health",
    "Cigna/Evernorth",
    "Horizon Blue Cross Blue Shield",
    "Optum Behavioral Health",
    "Amerigroup New Jersey",
    "Self-pay",
  ],
  "North Carolina": [
    "Aetna Behavioral Health",
    "Blue Cross Blue Shield North Carolina",
    "Optum Behavioral Health",
    "Self-pay",
  ],
  Texas: [
    "Aetna/Aetna Behavioral Health",
    "Blue Cross Blue Shield",
    "Cigna/Evernorth",
    "First Health/Coventry",
    "Humana POS/PPO",
    "Imagine Health",
    "MultiPlan",
    "Provider Network of America",
    "UnitedHealthcare/Optum Behavioral Health",
    "Scott & White Health Plan",
    "Self-pay",
  ],
}
